import axios from 'axios';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/nextjs';
import { getUrlByQueryParameterObj } from '@asset/function/queryStringFunctions';
import { getLoginPageUrlObject } from 'routes/account';
import _ from 'lodash';
import { UrlObject } from 'url';
import { LoginPageType } from 'constants/enum';
import pMemoize from 'p-memoize';
import ExpiryMap from 'expiry-map';

declare module 'axios' {
  export interface AxiosRequestConfig {
    loginUrl?: UrlObject;
    redirectUrl401?: UrlObject;
    redirectUrl403?: UrlObject;
  }
}
const tokenRefreshAPI = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_ENV_HOST}`,
  withCredentials: true,
});

const refreshTokenCache = new ExpiryMap(2000);
const refreshToken = pMemoize(
  async () => {
    return await tokenRefreshAPI.post('/api/users/token/refresh/');
  },
  { cache: refreshTokenCache },
);

const authAPI = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_ENV_HOST}`,
  withCredentials: true,
});

const handleResponse = (response) => {
  return response;
};

const handleError = async (error) => {
  const {
    method,
    url,
    params,
    data: requestData,
    headers,
    loginUrl,
    redirectUrl401,
    redirectUrl403,
  } = error.config; // axios의 error객체

  const getLoginUrlPath = (loginUrl: UrlObject) => {
    const { pathname, query } = loginUrl;
    return pathname + getUrlByQueryParameterObj('', query);
  };
  const redirectToUrl = (url: UrlObject) => {
    if (
      window.location.pathname.replace(/\/$/, '') ===
      url.pathname.replace(/\/$/, '')
    ) {
      return;
    }
    window.location.replace(getLoginUrlPath(url));
  };
  const redirectToLoginPage = () => {
    if (_.isNil(loginUrl)) {
      redirectToUrl(
        getLoginPageUrlObject({
          loginPageType: LoginPageType.NORMAL,
          redirectUrl: window.location.pathname + window.location.search,
        }),
      );
      return;
    }
    redirectToUrl(loginUrl);
  };

  const redirectTo401Page = !_.isNil(redirectUrl401)
    ? () => redirectToUrl(redirectUrl401)
    : redirectToLoginPage;

  const redirectTo403Page = !_.isNil(redirectUrl403)
    ? () => redirectToUrl(redirectUrl403)
    : redirectToLoginPage;
  if (error.response && error.response?.status && error.response?.data) {
    const { data: responseData, status } = error.response;

    if (Math.floor(status / 100) === 4) {
      if (status === 401) {
        if (
          url !== '/api/users/token/refresh/' &&
          url !== '/api/users/token/blacklist/' &&
          (responseData.message === 'ACCESS_TOKEN_EXPIRED' ||
            responseData.message === 'ACCESS_TOKEN_NOT_FOUND')
        ) {
          const originalRequest = error.config;
          originalRequest._retry = true;
          try {
            const { data } = await refreshToken();
            const { access } = data;

            originalRequest.headers['Authorization'] = `Bearer ${access}`;
            authAPI.defaults.headers.common[
              'Authorization'
            ] = `Bearer ${access}`;

            return authAPI(originalRequest);
          } catch (error) {
            error.config = {
              ...error.config,
              loginUrl,
              redirectUrl401,
              redirectUrl403,
            };
            await handleError(error);
            return Promise.reject(error);
          }
        } else if (responseData.code === 'LOGIN_FAILED') {
          toast.error('이메일 혹은 비밀번호를 확인해주세요.');
          return Promise.reject(error);
        } else {
          redirectTo401Page();
          localStorage.removeItem('persistAtom');
          return Promise.reject(error);
        }
      }
      if (status === 403) {
        redirectTo403Page();
        return Promise.reject(error);
      }

      if (status === 409) {
        return Promise.reject(error);
      }
    }

    if (Math.floor(status / 100) === 5) {
      toast.error('요청을 처리할 수 없습니다. 관리자에게 문의하세요.');
    }

    Sentry.setContext('API Request Detail', {
      method,
      url,
      params,
      requestData,
      headers,
    });
    Sentry.setContext('API Response Detail', {
      status,
      responseData,
    });
  }

  return Promise.reject(error);
};

authAPI.interceptors.response.use(handleResponse, handleError);

export { authAPI };
